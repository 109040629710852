<template>
  <q-layout>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Relatório de Cliques</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/" />
        <q-breadcrumbs-el label="Relatório de Cliques" exact />
      </q-breadcrumbs>
    </div>

    <q-page padding>
      <header-click-reports></header-click-reports>
      <list-click-reports></list-click-reports>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderClickReports from '../../components/reports/HeaderClickReportsConsolidated.vue';
import ListClickReports from '../../components/reports/ListClickReportsConsolidated.vue';

export default {
  components: { HeaderClickReports, ListClickReports },
  name: 'ClickReports'
};
</script>
