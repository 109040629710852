<template>
  <div>
    <q-toolbar class="q-pr-none q-mb-md justify-end">
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Relatórios </q-tooltip></q-btn
      >
    </q-toolbar>
    <q-intersection transition="slide-down" v-show="filter">
      <q-form ref="form" @submit.prevent="onFilterReports">
        <q-card flat v-if="filter" bordered class="q-mb-sm">
          <q-toolbar>
            <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
            <q-btn flat round class="q-ml-auto" icon="mdi-close" @click="filter = false" />
          </q-toolbar>
          <q-card-section class="q-pt-none row q-col-gutter-md">
            <div class="col-4">
              <date-input
                buttons
                outlined
                label="Data dos cliques"
                :v-model="params.date"
                @update="(value) => (params.date = value)"
                range
                :rules="[(val) => !!val || 'O campo data dos cliques é obrigatório']"
              />
            </div>
            <div class="col-4">
              <div class="row q-col-gutter-sm">
                <div class="col">
                  <q-select
                    v-model="params.displayMode"
                    :options="[
                      {
                        label: 'Por campanha',
                        value: 'campaign'
                      },
                      {
                        label: 'Por afiliado',
                        value: 'affiliate'
                      },
                      {
                        label: 'Por canal',
                        value: 'site'
                      },
                      {
                        label: 'Por dia',
                        value: 'day'
                      },
                      {
                        label: 'Por mês',
                        value: 'month'
                      },
                      {
                        label: 'Por ano',
                        value: 'year'
                      }
                    ]"
                    label="Modo de exibição"
                    :rules="[(val) => !!val || 'O campo de modo de exibição é obrigatório.']"
                    outlined
                  />
                </div>
              </div>
            </div>
            <div class="col-4">
              <select-request
                :v-model="params.network_manager_id"
                outlined
                endpoint="/user/affiliate/managers/redes"
                option-label="name"
                option-value="user_id"
                :label="$t('affiliate_manager')"
                clearable
                @update="(value) => (params.network_manager_id = value === null ? 0 : value.user_id)"
              />
            </div>
            <div class="col-4">
              <q-select
                use-input
                input-debounce="200"
                outlined
                clearable
                @clear="() => (params.campaign = null)"
                @filter="filterAdvertiser"
                @input="filterCampaignsOfAdv"
                v-model="params.advertiser"
                :options="advertiserFiltered"
                label="Advertiser"
                option-label="trademark"
              />
            </div>
            <div class="col-3">
              <q-select
                outlined
                v-model="params.segmentation"
                :options="optionsSegmentation"
                :option-label="mapDescription"
                option-value="id"
                label="Segmentação"
              >
                <template v-if="params.segmentation" v-slot:append>
                  <q-icon name="mdi-close" @click.stop="params.segmentation = null" class="cursor-pointer" />
                </template>
              </q-select>
            </div>
            <div class="col-5 q-mb-md">
              <q-select
                use-input
                input-debounce="200"
                @filter="filterCampaigns"
                outlined
                clearable
                v-model="params.campaign"
                :options="campaignsFiltered"
                label="Campanha"
                option-label="name"
                @popup-show="clearCampaign"
                @input="handleCampaignInput"
              />
            </div>

            <div class="col-4">
              <q-select
                use-input
                input-debounce="200"
                @clear="() => (params.channel = null)"
                @filter="filterAffiliate"
                @input="filterChannelsOfAff"
                outlined
                clearable
                v-model="params.affiliate"
                :options="affiliateFiltered"
                label="Afiliado"
                option-label="name"
              />
            </div>
            <div class="col-4">
              <!-- <q-select -->
              <!--   use-input -->
              <!--   input-debounce="200" -->
              <!--   @filter="filterChannels" -->
              <!--   outlined -->
              <!--   clearable -->
              <!--   v-model="params.channel" -->
              <!--   :options="channelsFiltered" -->
              <!--   label="Canal" -->
              <!--   option-label="name" -->
              <!--   @popup-show="clearChannel" -->
              <!--   @input="handleChannelInput" -->
              <!-- /> -->
              <select-request
                :v-model="params.manager_id"
                :endpoint="'/user/managers-unblocked'"
                label="Gerente"
                outlined
                clearable
                option-label="name"
                option-value="user_id"
                @update="(val) => (params.manager_id = val === null ? null : val.user_id)"
              />
            </div>

            <div class="col-4">
              <q-select
                use-input
                input-debounce="200"
                @filter="filterChannels"
                outlined
                clearable
                v-model="params.channel"
                :options="channelsFiltered"
                label="Canal"
                option-label="name"
                @popup-show="clearChannel"
                @input="handleChannelInput"
              />
            </div>

            <div class="col-6">
              <q-checkbox
                v-model="params.test_campaigns"
                :false-value="true"
                :true-value="false"
                :label="$t('dont_show_test_campaign')"
              />
            </div>

            <div class="col-6 text-right">
              <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
              <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" type="submit" />
            </div>
          </q-card-section>
        </q-card>
      </q-form>
    </q-intersection>
  </div>
</template>

<script>
import ReportMixin from '../../mixins/ReportMixin';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';
import ChannelService from '../../services/ChannelService';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';

export default {
  name: 'HeaderClickReports',
  mixins: [ReportMixin, ChannelService],
  components: { DateInput, SelectRequest },

  data() {
    return {
      filter: true,
      campaignList: [],
      campaignsFiltered: [],
      channelsList: [],
      channelsFiltered: [],
      affiliateList: [],
      affiliateFiltered: [],
      advertiserList: [],
      advertiserFiltered: [],
      optionsSegmentation: [],

      params: {
        advertiser: null,
        campaign: null,
        affiliate: null,
        channel: null,
        displayMode: null,
        date: null,
        test_campaigns: false,
        manager_id: null,
        network_manager_id: null
      }
    };
  },

  created() {
    this.getCampaign();
    this.getChannels();
    this.getAffiliates();
    this.getAdvertiser();
    this.getSegmentations();
  },

  methods: {
    async getSegmentations() {
      try {
        const { data } = await this.$http.get('redirect?app=advertiser&path=/api/get/campaign/segmentation');
        this.optionsSegmentation = data;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    mapDescription(item) {
      switch (item.id) {
        case 'AFILIO':
          return 'Campanhas Afilio';
        case 'BET':
          return 'Campanhas BET';
        case 'CJ':
          return 'Campanhas CJ';
      }
    },

    filterCampaigns(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        const advertiserId = this.params.advertiser ? this.params.advertiser.id : null;

        let uniqueIds = {};
        this.campaignsFiltered = this.campaignList.filter((item) => {
          const nameIncludesNeedle = item.name.toLocaleLowerCase().includes(needle);
          const idIncludesNeedle = item.id.toString().includes(needle);
          const match =
            (nameIncludesNeedle || idIncludesNeedle) && (!advertiserId || item.advertiser_id === advertiserId);
          if (match) {
            uniqueIds[item.id] = item;
          }
          return match;
        });

        this.campaignsFiltered = Object.values(uniqueIds);
      });
    },

    filterChannels(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle && !this.params.affiliate) this.channelsFiltered = this.channelsList;
        else if (!needle && this.params.affiliate)
          this.channelsFiltered = this.channelsList.filter((item) => item.affiliate_id == this.params.affiliate.id);

        this.channelsFiltered = this.channelsFiltered.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterAffiliate(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.affiliateFiltered = this.affiliateList;

        this.affiliateFiltered = this.affiliateList.filter((v) => v.name.toLocaleLowerCase().indexOf(needle) > -1);
      });
    },

    filterAdvertiser(val, update) {
      update(() => {
        const needle = val.toLocaleLowerCase();
        if (!needle) this.advertiserFiltered = this.advertiserList;

        this.advertiserFiltered = this.advertiserList
          .filter((v) => {
            const trademarkIncludesNeedle = v.trademark.toLocaleLowerCase().includes(needle);
            const idIncludesNeedle = v.id.toString().includes(needle);
            return trademarkIncludesNeedle || idIncludesNeedle;
          })
          .map((v) => {
            return {
              ...v,
              trademark: `${v.trademark} (ID:${v.id})`
            };
          });
        this.prevVal = needle;
      });
    },

    //Ele realiza esse filtro
    //Porém quando eu clico no input, por algum motivo estão voltando todos os canais da lista.

    filterChannelsOfAff(val) {
      this.params.channel = null;
      this.params.affiliate = val === null ? null : val;
      this.channelsFiltered = this.channelsList.filter((item) => item.affiliate_id == val.id);
    },

    filterCampaignsOfAdv(val) {
      this.params.campaign = null;
      this.params.advertiser = val === null ? null : val;

      if (this.params.advertiser) {
        this.campaignsFiltered = this.campaignList
          .filter((campaign) => campaign.advertiser_id === this.params.advertiser.id)
          .map((campaign) => ({
            ...campaign,
            name: `${campaign.name} (ID:${campaign.id})`
          }));
      } else {
        this.campaignsFiltered = [];
      }
    },

    handleChannelInput(val) {
      if (val) {
        this.params.affiliate = this.affiliateList.filter((aff) => aff.id == val.affiliate_id)[0];
      }
    },

    handleCampaignInput(val) {
      if (val) {
        this.params.campaign = val;
        const advertiser = this.advertiserList.find((adv) => adv.id === val.advertiser_id);
        if (advertiser) {
          this.params.advertiser = {
            ...advertiser,
            trademark: `${advertiser.trademark} (ID:${advertiser.id})`
          };
        }
      }
    },

    clearCampaign() {
      !this.params.advertiser ? (this.campaignsFiltered = this.campaignList) : '';
    },

    clearChannel() {
      !this.params.affiliate ? (this.channelsFiltered = this.channelsList) : '';
    },

    async getAdvertiser() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('redirect?app=ADVERTISER&path=/api/get/advertiser');

        if (status == 200) {
          this.advertiserList = data;
          this.advertiserFiltered = data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCampaign() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=ADVERTISER&path=/api/get/campaign');
        if (status == 200) {
          this.campaignList = data.map((item) => ({
            ...item,
            name: `${item.name} (ID:${item.id})`
          }));
          this.campaignsFiltered = this.campaignList;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getChannels() {
      this.onLoading(true);
      try {
        const { data, status } = await this.getListChannels();
        if (status == 200) {
          this.channelsList = data.map((item) => ({
            name: `${item.name} - ${item.id}`,
            id: item.id,
            affiliate_id: item.affiliate_id
          }));
          this.channelsFiltered = this.channelsList;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getAffiliates() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=AFFILIATE&path=/api/get/affiliate/search');

        if (status == 200) {
          this.affiliateList = data.map((item) => ({
            name: `${item.name} - ${item.id}`,
            id: item.id
          }));
          this.affiliateFiltered = this.affiliateList;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    clearFilter() {
      for (let i in this.params) this.params[i] = null;
      this.params.test_campaigns = false;
      this.ActionSetFilterReports();
      this.ActionSetReports();
    },

    onFilterReports() {
      let filterSelect = [];
      if (this.params.advertiser) filterSelect.push(`advertiser_id=${this.params.advertiser.id}`);
      if (this.params.segmentation) filterSelect.push(`segmentation=${this.params.segmentation.id}`);
      if (this.params.campaign) filterSelect.push(`campaign_id=${this.params.campaign.id}`);
      if (this.params.affiliate) filterSelect.push(`affiliate_id=${this.params.affiliate.id}`);
      if (this.params.channel) filterSelect.push(`site_id=[${this.params.channel.id}]`);
      if (this.params.manager_id) filterSelect.push('manager_id=' + this.params.manager_id);
      if (this.params.network_manager_id) filterSelect.push('network_manager_id=' + this.params.network_manager_id);

      if (this.params.date) {
        let [from, , to] = this.params.date.split(' ');
        from = this.formatToEn(from);
        to = this.formatToEn(to);
        filterSelect.push(`start_date=${from}`);
        filterSelect.push(`end_date=${to}`);
      }

      filterSelect.push(`filter=${this.params.displayMode.value}`);

      this.params.test_campaigns ? filterSelect.push(`test_campaigns=1`) : filterSelect.push(`test_campaigns=0`);

      this.ActionSetFilterReports(filterSelect.join('%26'));
      this.getConsolidatedClickReport();
    }
  }

  //   watch: {
  //     params: {
  //       handler() {
  //         this.$refs.form.submit();
  //         if (this.params.date != null && this.params.displayMode != null) this.onFilterReports();
  //       },
  //       deep: true
  //     }
  //   }
};
</script>
