import { render, staticRenderFns } from "./ClickReportsConsolidated.vue?vue&type=template&id=65f1082d"
import script from "./ClickReportsConsolidated.vue?vue&type=script&lang=js"
export * from "./ClickReportsConsolidated.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QPage from 'quasar/src/components/page/QPage.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QToolbarTitle,QBreadcrumbs,QBreadcrumbsEl,QPage});
